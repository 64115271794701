<template>
  <div class="partner">
    <div class="container">
      <div class="about-title">
        <h1 class="primary-title">公司简介</h1>
        <h2 class="secondary-title">COMPANY INTRODUCE</h2>
      </div>
      <div class="about-content">
        <div class="about-item-content">
          <div class="about-item-title">公司概况</div>
          <div class="about-item-body">
            <p>
              上海嗨喵网络科技有限公司是一家以互联网技术为核心的创新性企业，公司采用先进的前后端技术与严谨的管理制度，坚持以“让客户满意，为客户创造价值”为服务宗旨。
            </p>
            <p>
              主要从事大屏幕互动服务平台的提供、支持与定制；App定制与开发；行业软件定制与开发；微信公众号、小程序、桌面程序开发等。
            </p>
            <p>
              公司拥有完整、专业的研发、产品、运营、客服、市场等团队，随着互联网的发展与普及，越来越多的主办方使用互联网技术手段来举行现场活动、组织会议。上海嗨喵网络科技有限公司凭借丰富的运营经验，借助强大的技术开发实力，为您提供全面的优质服务。
            </p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">团队管理</div>
          <div class="about-item-body">
            <p>
              任家松，上海嗨喵网络科技有限公司董事长兼CEO，河海大学学士，复旦大学博士，曾任职上市公司诺德股份（600110）副董事长，杉杉集团（600884）常务副总经理、杉杉科技总经理，作为天使投资人参与创办和投资了多家高科技企业，拥有丰富的资本运作经验和深厚的互联网产业资源。
            </p>
            <p>
              李伟民，上海嗨喵网络科技有限公司CFO，中国注册会计师、中国注册税务师，作为税务顾问曾参与了杉杉集团上市财务顾问;为多家集团公司提供业务重组税务安排,为多家跨国公司及A股上市公司提供重组税务咨询意见及税务安排。
            </p>
            <p>
              上小官，上海嗨喵网络科技有限公司市场总监，传奇（中国）主持人联盟成员，胡白徒弟，中华司仪婚尚达人，中华婚庆网络评选风云人物，10余年婚礼主持，培育出了主持人、演讲师、营销达人以及多位网络直播带货官，上小官具备强劲的执行力和区域影响力，在市场开拓、婚庆人职业化等方面积累了宝贵的经验。
            </p>
            <p>
              Tiger，上海嗨喵网络科技有限公司CTO，曾任职腾讯高级开发工程师、火影忍者页游产品经理，连续创业者，拥有超过15年的软件开发经验和11年的软件开发管理经验，曾亲自负责和指导过多个大项目的研发管理和项目实施
              。
            </p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">主要产品</div>
          <div class="about-item-body">
            <p>
              嗨喵悦动：大屏场景下互动服务平台；提供3D签到、签到簿 、倒计时
              、弹幕上墙 、霸屏 、抢宏包 、拔河 、赛车
              、数钱、幸运夺宝、摇一摇、宏包雨 、扭一扭
              、转一转、开宝箱、、幸运宏包墙、抽奖
              、投票、猜明星、猜成语、猜英雄、猜歌名、火眼金睛等现场互动功能。
            </p>
            <p>
              现场只需一块LED大屏幕、一台操作电脑就能引爆现场气氛，High翻全场。
            </p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">公司文化</div>
          <div class="about-item-body">
            <p>迅速反应、以变应变、马上行动、注重结果</p>
            <p>用户第一：全方位尊重用户服务用户</p>
            <p>诚实守信：绝不改任何一个后台数据</p>
            <p>公开透明：让用户清晰知道自己收益</p>
            <p>政策稳定：绝不搞夕令朝改儿戏市场</p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">公司愿景</div>
          <div class="about-item-body">
            <p>
              利用互联网技术为客户提供更加优质的产品和服务，给客户的活动现场带来更多的花样、乐趣和激情。
            </p>
          </div>
        </div>
      </div>
    </div>
    <myFooter />
  </div>
</template>

<script>
import myFooter from '@/components/myFooter.vue';

export default {
  name: 'company',
  metaInfo: {
    title: '嗨喵悦动 - 嗨喵悦动登录_嗨喵悦动大屏互动',
    meta: [
      {
        name: 'keywords',
        content:
          '嗨喵悦动,嗨喵悦动大屏互动,嗨喵悦动官网,嗨喵悦动登录,微信墙,微信互动,现场互动,嗨现场',
      },
      {
        name: 'description',
        content:
          '嗨喵悦动是一款免费便捷炫酷的活动大屏幕现场互动软件，适用于企业年会、酒吧、婚礼、商业庆典峰会等活动现场，包括微信上墙送祝福、扫码签到、抽奖投票、摇一摇宏包雨等有趣的大屏互动游戏，让活动更精彩！',
      },
    ],
  },
  components: { myFooter },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  position: relative;
}
.banner > .img {
  width: 100%;
  height: 50%;
  background-color: #f9f9f9;
  vertical-align: middle;
  border: 0;
}

.about-title {
  top: 15%;
  padding: 15px 37.5px;
  width: 100%;
  color: #000;
  position: relative;
}

.primary-title {
  font-size: 30px;
}

.secondary-title {
  margin-top: 7.5px;
  padding-left: 22.5px;
  font-size: 15px;
  font-weight: 500;
  font-family: "Times New Roman", serif;
}

.container {
  position: relative;
}

.about-content {
  margin: 0px auto 0;
  padding: 22.5px 37.5px;
  width: 100%;
  max-width: 817.5px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0px 15px 11.25px -3.75px #d3cfc5;
}

.about-item-content {
  margin-top: 7.5px;
}

.about-item-title {
  color: #ff4141;
  font-size: 12px;
  margin-bottom: 15px;
  font-weight: bolder;
}

.about-item-body {
  margin-bottom: 22.5px;
  line-height: 1.5;
  text-indent: 2em;
}

p {
  margin: 0 0 7.5px;
}
</style>
